html, body, #root {
  height: 100%;
  overflow: hidden;
  font-family: 'Roboto', sans-serif;
}

.app-root {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  z-index: 4;
}

.demo0 {
  position: relative;
  margin: 5px 3px 10px;
  /* width: 900px;
  height: 900px; */
  flex: 1;
}

.demo0-block {
  position: absolute;
  border-radius: 4px;
}

.demo0-dustball {
  position: absolute;
  border-radius: 500px;
  background-color: black;
}

.rotating-video {
  animation: rotation 4s infinite linear;
}

@keyframes rotation {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
}

.dusty {
  position: absolute;
  width: 8px;
  height: 8px;
  border-radius: 500px;
  background-color: rgba(29, 29, 29, 0.8);
  cursor: pointer;
  z-index: 5;
  animation: grow linear 1s infinite alternate;
}

.dusty.white {
  background-color: white;
}

@keyframes grow {
  from {
      transform: scale(1);
  }
  to {
      transform: scale(1.35);
  }
}

@keyframes fadeColor {
  from {
    background-color: rgba(29, 29, 29, 0.8);
  }
  to {
    background-color: rgba(29, 29, 29, 1);
  }
}

.title-page {
  background-color: black;
  height: 100%;
  width: 100%;
  transition: transform 3s ease-in-out;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 5;
  position: absolute;
  color: white;
}

.title-page.started {
  transform: translateY(9999px);
}

.title-page.ended h2{
  margin: 5px;
}

.starter {
  padding: 10px;
  border: 2px solid white;
  border-radius: 2px;
  font-weight: bold;
  cursor: pointer;
  width: 20px;
  height: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.starter:hover {
  background-color:rgba(29, 29, 29, 1)
}

.starter.black {
  position: absolute;
  border: 2px solid rgba(29, 29, 29, 0.8);
  z-index: 6;
}

.starter.black:hover {
  background-color:rgba(29, 29, 29, 0.1)
}

.starter-text {
  width: 200px;
  position: absolute;
  user-select: none;
  left: -8px;
  top: -38px;
}